
.home-h2{
    margin-bottom: 24px;
    font-size: 28px;
    font-weight: 400;
    line-height: 1.35;
    text-align: center;
    text-transform: uppercase;

    @media (max-width: 767px){
        font-size: 1.5em;
        margin-bottom: 12px;
    }
}
.home-static-container{

    > p{
        margin-bottom: 1.55rem;

        &:last-child{
            margin-bottom: 0;
        }
    }
}
.theme-4-header{
    position: relative;
    z-index: 1;

    @media (max-width: 767px) {
        height: 70vw;
    }
    img{
        object-fit: cover;
    }
}
.theme-4-header-2{

    @media (max-width: 767px) {
        height: auto;
    }
}
.theme-4-header-new{
    min-height: 40vw;
    padding-bottom: 60px;
    background-color: #31353b;
    color: #ffffff;

    @media (max-width: 767px) {
        min-height: 70vw;
    }
    img{
        height: 45vw;
        border-radius: 0 0 50% 50% / 0px 0px 10% 10%;

        @media (max-width: 767px) {
            height: 70vw;
        }
    }
}
.theme-4-header-text{
    position: absolute;
    top: 10vw;
    left: 10vw;
    color: #fff;
    max-width: 700px;
    z-index: 1;

    @media (max-width: 1199px) {
        top: 7vw;
        left: 7vw;
        max-width: 500px;
    }
    @media (max-width: 767px){
        max-width: 80%;
        top: 12vw;
        left: 24px;
    }
    h1{
        font-size: 50px;
        font-weight: 400;
        line-height: 1.36;
        margin-bottom: 64px;

        @media (max-width: 1199px){
            font-size: 36px;
            margin-bottom: 55px;
        }
        @media (max-width: 767px){
            font-size: 7vw;
            margin-bottom: 1em;
        }
    }
    > .btn{
        @media (min-width: 1200px) {
            padding: 1.24em 2.1em;
        }
        @media (max-width: 767px){
            font-size: 14px;
        }
    }
}
.theme-4-main{
    background-color: #31353b;
    color: #ffffff;
    position: relative;
    padding-top: 200px;
    margin-top: -90px;
    padding-bottom: 75px;

    @media (max-width: 767px){
        padding-top: 150px;
        padding-bottom: 36px;
    }

    .home-static-container-2{
        margin-bottom: 60px;

        @media (max-width: 767px){
            margin-bottom: 36px;
        }
    }
}
.theme-4-bg-1{
    position: absolute;
    left: -1px;
    top: 0;
    z-index: 0;
    opacity: .7;
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.theme-4-new-main{
    padding-bottom: 0;
    padding-top: 0;
    overflow: visible;
    margin-top: 0;
    background-color: #ffffff;
    color: #000000;
}
.theme-4-new-main-2{
    position: relative;
}
.theme-4-new-section-1{
    padding: 48px 0;
    background-color: #31353b;
    color: #ffffff;

    .home-static-container-2{
        margin-bottom: 0;
    }
}
.theme-4-new-section-2{
    padding: 30px 0;
    background-color: #31353b;
    color: #ffffff;

    @media (max-width: 767px){
        padding: 30px 0;
    }
    .home-static-container{
        padding: 30px 24px;

        @media (max-width: 767px){
            padding: 30px 20px;
        }
    }
}
.theme-4-service{
    max-width: 1092px;
    padding: 12px 0;
    margin: 0 auto -24px;
}
.theme-4-service-container{
    margin-left: -12px;
    margin-right: -12px;
}
.theme-4-service-item{
    width: 33.33%;
    max-width: 320px;
    padding: 12px;
    text-align: center;

    @media (max-width: 767px) {
        width: 100%;
        margin: 0 auto 30px;
        padding: 10px 12px;
    }
    > img{
        margin-bottom: 20px;
        width: auto;
        height: 50px;
    }
    > h3{
        font-size: 20px;
        margin-bottom: 21px;
        font-weight: 400;

        @media (max-width: 991px){
            font-size: 17px;
        }
    }
    > p{
        margin-bottom: 0;
        color: #A1A1A1;
        line-height: 1.4;
    }
}
.theme-4-our-goal{
    padding: 60px 0;
    width: 100%;

    @media (max-width: 767px){
        padding: 48px 0;
    }
}
.theme-4-our-goal-left{
    width: 52%;

    @media (max-width: 767px){
        width: 100%;
        text-align: center;
    }
    > h2{
        text-transform: uppercase;
        font-weight: 400;
        margin-bottom: 20px;
        font-size: 28px;

        @media (max-width: 767px){
            font-size: 1.5em;
        }
    }
    > p{
        font-size: 18px;
        margin-bottom: 25px;
        line-height: 1.4;

        @media (max-width: 767px){
            font-size: 16px;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
}
.theme-4-our-goal-right{
    width: 46.1%;

    @media (max-width: 767px){
        width: 100%;
    }
}
.theme-4-trener{
    background-color: #232A35;
    color: #ffffff;
    padding: 72px 0 84px;

    @media (max-width: 767px){
        padding: 60px 0 52px;
    }
}
.theme-4-trener-bg{
    position: absolute;
    z-index: 0;
    height: 100%;
    width: 100%;
    left: -1px;
    top: 0;
    object-fit: cover;
}
.theme-4-trener-container{
    max-width: 876px;
    margin: 0 auto;
    padding: 24px 0;
}
.theme-4-trener-item{
    width: 292px;

    @media (max-width: 1159px) {
        display: flex;
        flex-direction: row!important;
        width: 100%;
        margin-bottom: 10px;
    }
    @media (max-width: 640px){
        flex-direction: column!important;
    }

    > img{
        height: 292px;
        width: 100%;
        object-fit: cover;

        @media (max-width: 1159px){
            width: 292px;
        }
        @media (max-width: 640px){
            width: 100%;
        }
    }
}
.theme-4-trener-item-text{
    padding: 40px 20px 20px 25px;
    height: 292px;
    background-color: #ffffff;
    color: black;
    overflow: hidden;

    @media (max-width: 1159px){
        flex-grow: 1;
    }
    > h3{
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 15px;
    }
    > p{
        font-size: 14px;
        line-height: 1.4;
        margin-bottom: 8px;
        display: -webkit-box;
        -webkit-line-clamp: 7;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    > span{
        font-size: 14px;
        min-height: 20px;
        display: inline-block;
        width: 100%;
    }
}
.theme-4-trener-new{
    padding: 0;

    .home-static-container{
        padding: 60px 20px;
    }
}
.btn.btn-1.btn-1-home{
    margin-top: 30px;
}
.theme-4-feedback{
    padding: 72px 0;

    @media (max-width: 767px){
        padding: 60px 0;
    }
}
.theme-4-feedback-container{
    padding: 24px 0;
}
.theme-4-feedback-left{
    width: 50%;
    position: relative;
    padding-left: 112px;
    padding-top: 40px;

    @media (max-width: 991px){
        width: 45%;
        padding-left: 30px;
    }
    @media (max-width: 767px){
        width: auto;
        padding-bottom: 20px;
        margin: 0 auto;
        max-width: 100%;
    }
    img{
        border: solid 4px var(--btn1-background-color);
        position: relative;
        z-index: 1;
    }
}
.theme-4-feedback-blue{
    background-color: var(--btn1-background-color);
    width: 175px;
    height: 504px;
    max-height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
.theme-4-feedback-right{
    width: 50%;
    padding-left: 30px;
    padding-top: 24px;

    @media (max-width: 991px){
        width: 55%;
        padding-left: 30px;
    }
    @media (max-width: 767px){
        width: 100%;
        padding-left: 0;
    }
}
.theme-4-feedback-right-item{
    padding-left: 57px;
    margin-bottom: 42px;
    margin-top: 11px;

    @media (max-width: 767px){
        padding-left: 40px;
    }
    > h3{
        font-size: 18px;
        font-weight: 700;
    }
    > p{
        line-height: 1.4;
        margin-bottom: 14px;
    }
}
