@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local(''),
    url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/poppins-v20-latin-300.woff2') format('woff2');
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local(''),
    url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/poppins-v20-latin-regular.woff2') format('woff2');
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local(''),
    url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/poppins-v20-latin-500.woff2') format('woff2');
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local(''),
    url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/poppins-v20-latin-600.woff2') format('woff2');
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local(''),
    url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/poppins-v20-latin-700.woff2') format('woff2');
}

@font-face {
    font-family: "NotoSansArmenian";
    font-weight: 300;
    font-display: swap;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/noto-sans-armenian-v39-armenian_latin-300.woff2') format("woff2");
}
@font-face {
    font-family: "NotoSansArmenian";
    font-weight: 400;
    font-display: swap;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/noto-sans-armenian-v39-armenian_latin-regular.woff2') format("woff2");
}
@font-face {
    font-family: "NotoSansArmenian";
    font-weight: 500;
    font-display: swap;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/noto-sans-armenian-v39-armenian_latin-500.woff2') format("woff2");
}
@font-face {
    font-family: "NotoSansArmenian";
    font-weight: 600;
    font-display: swap;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/noto-sans-armenian-v39-armenian_latin-600.woff2') format("woff2");
}
@font-face {
    font-family: "NotoSansArmenian";
    font-weight: 700;
    font-display: swap;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/noto-sans-armenian-v39-armenian_latin-700.woff2') format("woff2");
}

body{
    font-family: Poppins, NotoSansArmenian, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

@import 'home';
@import '../../global/tenants-global';


:root {
    --nav-background: #2b3038;
    --nav-link: #ffffff;
    --nav-link-hover: #0895dd;


    --btn1-background-color: #09AAFB;
    --btn1-background-hover-color: #0895dd;
    --btn1-text-color: #ffffff;
    --btn1-text-hover-color: #ffffff;

    --btn1-outline-background-color: #ffffff;
    --btn1-outline-background-hover-color: #09AAFB;
    --btn1-outline-text-color: #0895dd;
    --btn1-outline-text-color-hover: #ffffff;
    --btn1-outline-border-color: #09AAFB;
    --btn1-outline-border-hover-color: #09AAFB;


    --btn2-background-color: #8f8f8f;
    --btn2-background-hover-color: #747474;
    --btn2-text-color: #ffffff;
    --btn2-text-hover-color: #ffffff;

    --btn2-outline-background-color: #ffffff;
    --btn2-outline-background-hover-color: #8f8f8f;
    --btn2-outline-text-color: #000000;
    --btn2-outline-text-color-hover: #ffffff;
    --btn2-outline-border-color: #8f8f8f;
    --btn2-outline-border-hover-color: #8f8f8f;


    --color-1: #2092F3;
    --color-2: #8f8f8f;

    --course-background: #232A35;
    --course-background-hover: #ffffff;
    --course-color: #ffffff;
    --course-color-hover: #1D1D1D;
    --course-color-hr: #232A35;
    --course-color-hr-hover: #ffffff;
    --course-border: transparent;
    --course-radius: 16px;
}
h1,h2,h3,h4,h5,h6, p{
    min-height: 17px;
}
.btn{
    border-radius: 2em;
}
.home-course-item-block hr{
    display: none;
}


.theme-4-info-header{
    min-height: 36.5vw;
    display: flex;
    align-items: center;
    background-color: #232A35;

    @media (max-width: 767px) {
        flex-direction: column;
        min-height: auto;
        padding: 35px 15px;
    }
}
.theme-4-info-header-text{
    position: relative;
    z-index: 1;
    font-size: clamp(10px, 1.172vw, 18px);
    color: #ffffff;
    margin: 0 auto;
    text-align: center;
    max-width: 700px;

    @media (max-width: 767px){
        font-size: 16px;
    }
    h1{
        font-weight: 400;
        line-height: 1.35;
        font-size: 2.5em;
        margin-bottom: .9em;

        @media (max-width: 767px){
            line-height: 1.25;
            font-size: 24px;
            margin-bottom: 1.4em;
        }
    }
    > .btn{
        font-size: 1em;
    }
}
.theme-4-info-header-img{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
}
.theme-4-info-section{
    padding: 35px 12px;

    @media (max-width: 767px){
        padding: 25px 12px;
    }
    .theme-all-info-section-2-container{
        padding: 35px 12px;

        @media (max-width: 767px){
            padding: 25px 12px;
        }
    }
}
